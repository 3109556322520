import {useEffect, useState} from "react";
import './App.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {library} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {fab} from '@fortawesome/free-brands-svg-icons'
import {Clientes, Contacto, Footer, Home, Navigator, Servicios} from "./pages";
library.add(fas)
library.add(fab)

function WhatsappBt() {
    return <a href="https://wa.me/5491158075510?text=Hola!%20Te%20contacto%20desde%20la%20web,%20queria%20consultar..."
              className="whatsapp" target="_blank">
        <FontAwesomeIcon className="whatsapp-icon" icon={["fab", "whatsapp"]}/>
    </a>;
}

function App() {
    return (
        <>
            <Navigator/>
            <Home/>
            <Servicios/>
            <Clientes/>
            <Contacto/>
            <Footer/>
            <WhatsappBt/>
        </>
    );
}

export default App;
