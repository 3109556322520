import {useEffect, useState} from "react";
import {Card, Col, Container, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function ServiceItem({icono, title, description}) {
    return (
        <Col>
            <Card className="text-center" style={{height: '100%'}}>
                <Card.Body>
                    <Card.Subtitle style={{marginBottom: '2em', color: '#30A9F5C6'}}>{icono}</Card.Subtitle>
                    <Card.Title>{title}</Card.Title>
                    <Card.Text>
                        {description}
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
    )
}


export function Servicios() {
    return <section className="page-section" id="servicios">
        <h2 className="text-center mt-0">NUESTROS SERVICIOS</h2>
        <hr className="divider"/>
        <Container>
            <Row md={4}>
                <ServiceItem icono={<FontAwesomeIcon size="3x" icon={["fas", "gears"]}/>} title="Desarrollo a Medida"
                             description="Desarrollamos sistemas personalizados y enfocados en sus objetivos"/>
                <ServiceItem icono={<FontAwesomeIcon size="3x" icon={["fas", "code"]}/>} title="Aplicaciones WEB"
                             description="Especialistas en programación de plataformas y aplicaciones web autoadministrables, estables y escalables"/>
                <ServiceItem icono={<FontAwesomeIcon size="3x" icon={["fas", "mobile"]}/>} title="Mobile APPs"
                             description="Diseño, Programación multiplataforma y Publicación, empresa certificada por Google Play Store."/>
                <ServiceItem icono={<FontAwesomeIcon size="3x" icon={["fas", "bug-slash"]}/>}
                             title="Soporte de aplicaciones"
                             description="Brindamos soporte correctivo y evolutivo a aplicaciones existentes con amplio conocimiento en diferentes tecnologías"/>
            </Row>
        </Container>
    </section>;
}
