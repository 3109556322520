import {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";


export function Clientes() {
    return <section className="page-section bg-background" id="clientes">
        <div className="container px-4 px-lg-5">
            <div className="row gx-4 gx-lg-5 justify-content-center">
                <div className="col-lg-8">
                    <h2 className="mt-0 text-center">CLIENTES</h2>
                    <hr className="divider"/>
                    <h4 className="text-center">
                        Hemos desarrollado aplicaciones para clientes de distintos sectores de la industria.
                    </h4>
                    <Container className="mt-5">
                        <Row>
                            <Col>
                                <ul>
                                    <li>Control de calidad</li>
                                    <li>Construcción</li>
                                    <li>Transporte</li>
                                    <li>Comercio</li>
                                    <li>Talleres</li>
                                </ul>
                            </Col>
                            <Col>
                                <ul>
                                    <li>Comercio en internet</li>
                                    <li>Indumentaria</li>
                                    <li>Servicios para automotores</li>
                                    <li>Servicios financieros</li>
                                    <li>Emprendimientos inmobiliarios</li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    </section>;
}
