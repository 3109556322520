import {useEffect, useState} from "react";
import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";

export function Navigator() {
    const [isShrunk, setShrunk] = useState(false);

    useEffect(() => {
        const handler = () => {
            setShrunk((isShrunk) => {
                if (!isShrunk && (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20)) {
                    return true;
                }
                if (isShrunk && document.body.scrollTop < 4 && document.documentElement.scrollTop < 4) {
                    return false;
                }
                return isShrunk;
            });
        };

        window.addEventListener("scroll", handler);
        return () => window.removeEventListener("scroll", handler);
    }, []);

    return (
        <>
            <Navbar collapseOnSelect expand="lg" id="mainNav"
                    className={(isShrunk) ? 'fixed-top navbar-shrink' : 'fixed-top'}>
                <Container>
                    <Navbar.Brand href="#home"><img
                        src="/icono.svg"
                        alt="Logo"
                    /> MaguiTech</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                    <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                        <Nav>
                            <Nav.Item>
                                <Nav.Link href="#inicio">Inicio</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link href="#servicios">Servicios</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link href="#clientes">Clientes</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link href="#contacto">Contacto</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}


/*
;

 */
