import {useEffect, useState} from "react";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useForm} from "react-hook-form";

function Formulario() {
    const {register, handleSubmit, reset, formState: {errors}} = useForm();
    const onSubmit = data => {
        const formData = new FormData();
        formData.append('entry.803571738', data.name);
        formData.append('entry.533383653', data.mail);
        formData.append('entry.216588335', data.message);
        reset();

        fetch('https://docs.google.com/forms/u/2/d/e/1FAIpQLSeSxkfQxAPScokA4LldaJzjBUrJKZUj3oX09RR03MAeX1BXZw/formResponse', {
            mode: "cors",
            headers: {
                'Access-Control-Allow-Origin': '*'
            },
            method: 'POST',
            body: formData
        })
            .then((result) => {
                alert("Gracias, nos pondremos en contacto a la brevedad")
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }


    return (
        <Form id="formid" onSubmit={handleSubmit(onSubmit)}>
            <Container>
                <Row className="mt-3">
                    <Col xs={3}><Form.Label>Nombre</Form.Label></Col>
                    <Col xs={9}><Form.Control type="text" placeholder="Ingrese su nombre"  {...register("name", {
                        required: true,
                        maxLength: 20
                    })}  /></Col>
                </Row>
                <Row className="mt-3">
                    <Col xs={3}><Form.Label>Email</Form.Label></Col>
                    <Col xs={9}><Form.Control type="email"
                                              placeholder="Ingrese su mail" {...register("mail", {required: true})} /></Col>
                </Row>
                <Row className="mt-3">
                    <Col lg={12}><Form.Label>Mensaje</Form.Label></Col>
                    <Col lg={12}><textarea className="form-control"
                                           placeholder="Ingrese su consulta" {...register("message")}></textarea></Col>
                </Row>
                <Row className="mt-3">
                    <Col className="text-end" lg={{offset: 6, order: 'last'}}>
                        <Button variant="primary" type="submit">
                            Enviar
                        </Button>
                    </Col>
                </Row>

            </Container>
        </Form>
    )
}

export function Contacto() {
    return <section className="page-section" id="contacto">
        <Container className="container px-4 px-lg-5">
            <div className="row gx-4 gx-lg-5 justify-content-center">
                <div className="col-lg-8 col-xl-6 text-center">
                    <h2 className="mt-0">CONTÁCTENOS</h2>
                    <hr className="divider"/>
                    <p className="text-secondary h3 mb-5">¿Listo para comenzar tu próximo proyecto con nosotros?</p>
                    <p className="text-primary h3 mb-5">Envíanos un mensaje y nos pondremos en contacto lo antes
                        posible</p>
                </div>
            </div>
            <Container>
                <Row>
                    <Col md={12} className="text-center">
                        <div className="mt-5 link-secondary h3">
                            <FontAwesomeIcon icon={['fas', 'envelope']}/> <a
                            className="link-secondary"
                            href="mailto:fabian.derosa.git@gmail.com">Mail</a>
                        </div>
                        <div className="mt-5 link-secondary h3">
                            <FontAwesomeIcon icon={['fas', 'phone']}/> <a
                            className="link-secondary"
                            href="tel:541158075510">Llamanos</a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    </section>;
}
