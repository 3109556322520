import {useEffect, useState} from "react";

export function Home() {
    return <header className="masthead" id="inicio">
        <div className="container px-4 px-lg-5 h-100">
            <div className="row gx-4 gx-lg-5 h-100 align-items-center justify-content-center text-center">
                <div className="col-lg-8 align-self-end">
                    <h1 className="text-white font-weight-bold">Implementá esa solución que mejora tu negocio</h1>
                    <hr className="divider"/>
                </div>
                <div className="col-lg-8 align-self-baseline">
                    <p className="text-white-75 mb-5">
                        Con varios años de experiencia en el mercado, diseñamos diferentes aplicaciones web y mobile
                        utilizando las tecnologías mas adecuadas para cada cliente.
                    </p>
                </div>
            </div>
        </div>
    </header>;

}
